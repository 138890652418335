'use client'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import LeavePageGuard from '@tebuto/guards/leave-page/LeavePageGuard'
import { RouteChangesProvider } from '@tebuto/guards/leave-page/useRouteChangeEvents'
import MUIThemeProvider from '@tebuto/layout/MUIThemeProvider'
import SWRConfiguration from '@tebuto/layout/SWRConfiguration'
import { TebutoPersistGate } from '@tebuto/providers/PersistGate'
import Toaster from '@tebuto/providers/ToastContainer'
import { store } from '@tebuto/state/store'
import CookieBanner from 'react-gdpr-cookie-consent/dist/components/ConsentBanner'
import { Provider as ReduxProvider } from 'react-redux'

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <MUIThemeProvider>
            <ReduxProvider store={store}>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
                    <TebutoPersistGate>
                        <Toaster />
                        <CookieBanner />
                        <SWRConfiguration>
                            <RouteChangesProvider>
                                <LeavePageGuard>{children}</LeavePageGuard>
                            </RouteChangesProvider>
                        </SWRConfiguration>
                    </TebutoPersistGate>
                </LocalizationProvider>
            </ReduxProvider>
        </MUIThemeProvider>
    )
}
